<template>
  <div class="record">
    <div class="title">分销记录</div>
    <div>
      <van-pull-refresh v-model="refreshing">
        <van-list
          offset="2"
          v-model="loading"
          :finished="finished"
          :finished-text="finishText"
          @load="onLoad"
        >
          <van-cell v-for="item in tableData" :key="item.ros_id">
            <p>
              <span class="list_title">推广奖励金额：</span>
              <span class="list_des">{{item.credit/100}}</span>
            </p>
<!--            <p>-->
<!--              <span class="list_title">被推广用户id：</span>-->
<!--              <span class="list_des">{{item.customer_user_id}}</span>-->
<!--            </p>-->
            <p>
              <span class="list_title">奖励到账时间：</span>
              <span class="list_des">{{item.created_at}}</span>
            </p>
            <p>
              <span class="list_title">被推广用户名：</span>
              <span class="list_des">{{item.customer_user_name}}</span>
            </p>
            <p>
              <span class="list_title">描述：</span>
              <span class="list_des">{{item.describe}}</span>
            </p>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { getSaleListApi } from "api/AdminCenter";
export default {
  data() {
    return {
      current_page: 0,
      last_page: 1,
      total: 1,
      size: 15,
      tableData: [],
      type: {
        1: "APP客户端",
        2: "加速盒",
        3: "家庭加速盒",
        4: "直连账户",
        5: "账户充值",
        6: "APP+家庭加速盒",
      },
      statusStr: {
        0: "待支付",
        1: "支付成功",
        2: "支付失败",
        3: "订单关闭"
      },
      loading: false,
      finished: false,
      refreshing: false,
      finishText: ""
    };
  },
  methods: {
    //加载
    onLoad() {
      if (this.current_page < this.last_page) {
        this.getList({ page: this.current_page + 1, size: this.size });
      } else {
        this.finished = true;
      }
    },
    //获取列表数据
    getList(values) {
      getSaleListApi(values).then(res => {
        if (res.code == 0) {
          const { current_page, total, data, last_page } = res.data;
          data.map((item, index) => {
            item.index = index;
          });
          this.tableData = [...this.tableData, ...data];
          this.current_page = current_page;
          this.total = total;
          this.last_page = last_page;
          this.loading = false;
          this.finishText = res.data.length > 0 ? "没有更多了" : "暂无数据";
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.record {
  background: #fff;
  padding: 16px;
  padding-top: 80px;
  padding-bottom: 300;
  .title {
    padding-bottom: 35px;
    font-size: 30px;
    color: #517cfc;
  }
  .van-pull-refresh {
    p {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      .list_title {
        font-size: 16px;
        color: #7e7e7e;
      }
      .list_des {
        font-size: 16px;
        color: #202020;
      }
    }
    .van-cell {
      margin-top: 16px;
      padding: 10px 0;
    }
  }
}
</style>